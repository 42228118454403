<!-- @format -->
<script>
	import { onMount, createEventDispatcher } from 'svelte'
	import { procedure, transmitter, options, bankruptIsPerson, bankruptIsOrganization } from 'base_stores'
	import { fetchGet, fetchPost, fetchPut, fetchDelete } from 'utils/fetch'
	import { isBlank, isPresent } from 'utils/tools'
	import { clearScans } from '~/svelte/_shared/scans_sets/scans_set.js'
	import Select2 from '~/svelte/components/ui/select2.svelte'
	import InputCurrency from '~/svelte/components/ui/input_currency.svelte'
	import Checkbox from '~/svelte/components/ui/checkbox.svelte'
	import Input from '~/svelte/components/ui/input.svelte'
	import IntegerInput from '~/svelte/components/ui/input_integer.svelte'
	import Textarea from '~/svelte/components/ui/textarea.svelte'
	import Datepicker from '~/svelte/components/ui/datepicker.svelte'
	import { showModal, hideModal } from '~/svelte/components/modal.svelte'
	import CounterpartyModal from '~/svelte/counterparties/_modal.svelte'
	import CurrencyModal from '~/svelte/currencies/_modal.svelte'
	import ScansSet from '~/svelte/_shared/scans_sets/_set_field.svelte'
	import Case from 'case'

	export let bankAccountId;
	export let modalId;
	export let noBankSelected;

	export let handleSubmit;
	export let handleCancel;
	export let handleDelete;
	export let disabled = false;

	const dispatch = createEventDispatcher();

	$: nullBankAccount = {
		counterparty_id: null,
		opened_at: null,
		closed_at: null,
		is_main: null,
		kind: defaultKind,
		number: null,
		balance: 0.0,
		current_balance: 0.0,
		currency_id: null,
		measures_taken: null,
		result: null,
	};

	let isNewInstance
	let bankAccount = { ...nullBankAccount }
	let tempCounterparty = null
	let tempCurrency = null
	let counterpartyId
	let defaultKind
	let currencyOptions = []
	let defaultCurrency
	let counterpartyKind
	let optionsKind
	let currentKindBankAccount
	let currencyKind
	let mounting = false

	$: method = isPresent(bankAccountId) ? fetchPut : fetchPost
	$: data = { ...bankAccount }
	$: noBankSelected = !bankAccount.balance ||
		!bankAccount.currency_id ||
		!bankAccount.number ||
		(!['cryptoex', 'electronic_payment_mean'].includes(counterpartyKind) && !bankAccount.counterparty_id) ||
		(['electronic_payment_mean'].includes(counterpartyKind) && !bankAccount.electronic_payment_service);
	$: defaultKind = $bankruptIsPerson ? 'current' : ($bankruptIsOrganization ? 'checking' : null)

	$: if (isPresent(counterpartyKind)) {
		getOptions();
	}

	const setCurrencyOptions = () => {
		currencyOptions = $options.currency.filter(c => c.kind == currencyKind);
		if (counterpartyKind == 'cryptoex') {
			currencyOptions = [{ id: 'new', text: 'Добавить валюту' }, ...currencyOptions];
		}
	};

	const setCurrencyParams = () => {
		currencyKind = counterpartyKind == 'cryptoex' ? 'crypto' : 'fiat';
		setCurrencyOptions();
		defaultCurrency = currencyOptions.find(option => option.text.split(' ')[0] == (counterpartyKind == 'cryptoex' ? 'BTC' : 'RUB'));
		if (!bankAccount.id || !bankAccount.currency_id) {
			bankAccount.currency_id = defaultCurrency.id;
		}
	};

	$: if (bankAccount.kind != currentKindBankAccount && !mounting) {
		const currentCounterpartyKind = counterpartyKind
		counterpartyKind = ['brokerage', 'depository_brokerage', 'brokerage_sub', 'individual_investment'].includes(bankAccount.kind)
			? 'stockbroker'
			: ['card', 'current', 'special', 'trust_management', 'checking', 'credit', 'deposit', 'budget', 'iban'].includes(bankAccount.kind)
				? 'bank'
				: ['electronic_payment_mean'].includes(bankAccount.kind)
					? 'electronic_payment_mean'
					: 'cryptoex';
		if (!!currentKindBankAccount && counterpartyKind !== currentCounterpartyKind) { bankAccount.counterparty_id = null }
		if (counterpartyKind !== 'bank') { bankAccount.is_main = null }
		optionsKind = `counterparty${Case.capital(counterpartyKind)}`
		currentKindBankAccount = bankAccount.kind
		setCurrencyParams()
	}

	$: if (bankAccountId && bankAccountId !== bankAccount.id) {
		bankAccount = $transmitter.bankAccounts.find(account => account.id === bankAccountId);
	}

	$: if (isBlank(bankAccount)) {
		bankAccount = { ...nullBankAccount };
	}

	$: if (bankAccount.is_main) {
		bankAccount.result = `Счёт выбран основным счётом должника.${bankAccount.result ? `\n${bankAccount.result.replace(/Счёт выбран основным счётом должника.\s*/, '')}` : ''}`;
	} else {
		bankAccount.result = bankAccount.result && bankAccount.result.replace(/Счёт выбран основным счётом должника.\s*/, '');
	}

	$: if (counterpartyKind && counterpartyKind != 'electronic_payment_mean' && bankAccount.number) {
		bankAccount.number = bankAccount.number.slice(0, 20);
	}

	$: counterpartyParams = {
		bank: {
			label: 'Наименование банка',
			newText: 'банк',
			options: $options.counterpartyBank,
			placeholder: 'Банк',
		},
		stockbroker: {
			label: 'Наименование брокера',
			newText: 'брокера',
			options: $options.counterpartyStockbroker,
			placeholder: 'Брокер Инвестиции',
		},
		cryptoex: {
			label: 'Наименование криптовалютной биржи',
			newText: 'криптобиржу',
			options: $options.counterpartyCryptoex,
			placeholder: 'Binance',
		},
	};

	$: formFields = {
		isMain: {
			model: 'bank_account',
			attribute: 'is_main',
			label: 'Является основным',
			text: 'Да, является основным',
			disabled,
		},
		bankName: {
			disabled,
			required: counterpartyKind !== 'cryptoex',
			model: 'bank_account',
			attribute: 'counterparty_id',
			modify: 'showModalCounterpartyForm',
			checkSuitability: checkSuitabilityCounterparty,
			actionInUnsuitability: actionInUnsuitabilityCounterparty,
			...counterpartyParams[counterpartyKind],
		},
		kind: {
			required: true,
			model: 'bank_account',
			attribute: 'kind',
			placeholder: 'Выбрать вид счёта',
			label: 'Вид счёта',
			options: $options.bankAccountKind,
			disabled,
			withSearch: false,
		},
		electronicPaymentService: {
			required: true,
			model: 'bank_account',
			attribute: 'electronic_payment_service',
			label: 'Сервис электронных платежей',
			placeholder: 'Яндекс',
			disabled,
		},
		number: {
			required: true,
			model: 'bank_account',
			attribute: 'number',
			length: counterpartyKind != 'electronic_payment_mean' ? 20 : undefined,
			label: 'Номер счёта',
			placeholder: '12345678901234567890',
			disabled,
		},
		openedAt: {
			model: 'bank_account',
			attribute: 'opened_at',
			label: 'Дата отрытия счёта',
			placeholder: 'дд.мм.гггг',
			maskOptions: { mask: Date },
			disabled,
		},
		closedAt: {
			model: 'bank_account',
			attribute: 'closed_at',
			label: 'Дата закрытия счёта',
			placeholder: 'дд.мм.гггг',
			maskOptions: { mask: Date },
			disabled,
		},
		balance: {
			required: true,
			model: 'bank_account',
			attribute: 'balance',
			label: 'Остаток на начало процедуры',
			placeholder: '0,00',
			disabled,
		},
		currentBalance: {
			model: 'bank_account',
			attribute: 'current_balance',
			label: 'Текущий остаток',
			placeholder: '0,00',
			disabled,
		},
		currency: {
			required: true,
			model: 'bank_account',
			attribute: 'currency_id',
			placeholder: counterpartyKind == 'cryptoex' ? 'BTC' : 'RUB',
			label: 'Валюта',
			options: currencyOptions,
			disabled,
		},
		measuresTaken: {
			model: 'bank_account',
			attribute: 'measures_taken',
			label: 'Предпринятые меры',
			placeholder: 'Направлено уведомление о закрытии счёта',
			disabled,
		},
		result: {
			model: 'bank_account',
			attribute: 'result',
			label: 'Результат',
			placeholder: 'Счёт закрыт',
			disabled,
		},
	};

	$: filledRequiredFields = !noBankSelected

	$: if (isBlank(bankAccountId) && filledRequiredFields) { secretlyСreate() }

	const secretlyСreate = async () => {
		const response = await fetchPost('/api/private/bank_accounts', data)
		$transmitter.bankAccounts = response?.bank_accounts
		bankAccountId = response?.bank_account_id
	}

	const refreshScansSet = ({detail: scansSet}) => {
		$transmitter.bankAccounts = $transmitter.bankAccounts.map(s => s.id == bankAccountId ? { ...s, scans_set: scansSet } : s)
		bankAccount.scans_set = scansSet
	}

	handleSubmit = async () => {
		if (
			(!['cryptoex', 'electronic_payment_mean'].includes(counterpartyKind) && bankAccount.counterparty_id) ||
			['cryptoex', 'electronic_payment_mean'].includes(counterpartyKind)
		) {
			const response = await method(`/api/private/bank_accounts${bankAccountId ? `/${bankAccountId}` : ''}`, data)
			$transmitter = { ...$transmitter, bankAccounts: response.bank_accounts }

			const scansSet = await clearScans(bankAccount.scans_set, 'deleted')
			$transmitter.bankAccounts = $transmitter.bankAccounts?.map(s => s.id == bankAccountId ? { ...s, scans_set: scansSet } : s)

			hideModal(modalId)
			dispatch((bankAccountId ? 'update' : 'create'), response.bank_account_id)
		}
	};

	handleDelete = () => {
		fetchDelete(`/api/private/bank_accounts/${bankAccountId}`)
			.then((response) => {
				$transmitter = { ...$transmitter, bankAccounts: response.bank_accounts }
				dispatch('delete')
			})
	}

	handleCancel = () => {
		if (isNewInstance) {
			if (isPresent(bankAccountId)) { handleDelete() }
		} else {
			clearScans(bankAccount.scans_set, 'new')
		}

		bankAccount = { ...nullBankAccount }
	}

	const showModalCounterpartyForm = value => {
		if (value === 'new') {
			tempCounterparty = bankAccount.counterparty_id;
			openCounterpartyModalForm();
		}
	};

	const openCounterpartyModalForm = id => {
		counterpartyId = id;
		showModal('form-counterparty-modal');
	};

	const cancelCounterpartyModalForm = () => {
		bankAccount.counterparty_id = tempCounterparty;
		tempCounterparty = null;
	};

	const showModalCurrencyForm = value => {
		if (value === 'new') {
			tempCurrency = bankAccount.currency_id;
			openCurrencyModalForm();
		}
	};

	const openCurrencyModalForm = () => {
		showModal('form-currency-modal');
	};

	const cancelCurrencyModalForm = () => {
		bankAccount.currency_id = tempCurrency;
		tempCurrency = null;
	};

	const checkSuitabilityCounterparty = item => {
		if (counterpartyKind !== 'bank') {
			return true;
		}
		let counterparty = $options[optionsKind].find(opt => opt.id == item.id);
		if (isPresent(counterparty?.roles)) {
			return counterparty.roles.includes(counterpartyKind);
		} else {
			return true;
		}
	};

	const actionInUnsuitabilityCounterparty = async item => {
		let correspondent = $options[optionsKind].find(opt => opt.id == item.id);
		let roles = [...correspondent.roles, counterpartyKind];
		await fetchPut(`/api/private/counterparties/${correspondent.id}`, { roles: roles });
		$options[optionsKind].find(opt => opt.id == item.id).roles = roles;
	};

	const getOptions = async () => {
		if (counterpartyKind == 'electronic_payment_mean') {
			$options[optionsKind] = [];
			return;
		}

		let params;
		if (counterpartyKind == 'bank') {
			params = { possible_roles: ['bank'] };
		} else {
			params = { roles: [counterpartyKind] };
		}
		const response = await fetchGet('/api/private/counterparties/get_options_for_roles', params);
		$options[optionsKind] = [{ id: 'new', text: `Добавить ${counterpartyParams[counterpartyKind]['newText']}` }, ...response.options];
	};

	const refresh = async newCounterpartyId => {
		await getOptions();
		bankAccount.counterparty_id = newCounterpartyId;
	};

	const refreshCurrencies = async newCurrency => {
		$options.currency = [
			...$options.currency,
			{
				id: newCurrency.id,
				text: newCurrency.kind == 'crypto' ? `${newCurrency.code} - ${newCurrency.name}` : newCurrency.code,
				kind: newCurrency.kind,
				user_id: newCurrency.user_id,
			},
		];
		setCurrencyOptions();
		bankAccount.currency_id = newCurrency.id;
	};

	onMount(async () => {
		mounting = true
		isNewInstance = isBlank(bankAccountId)

		if (isBlank($options.bankAccountKind)) {
			const response = await fetchGet('/api/private/bank_accounts/kind_options');
			$options = { ...$options, bankAccountKind: response.options };
		}

		if (isBlank($options.counterpartyBank) || isBlank($options.counterpartyStockbroker) || isBlank($options.cryptoex)) {
			const response = await fetchGet('/api/private/counterparties/get_options_for_roles', { 'roles[]': ['bank', 'stockbroker', 'cryptoex'] });
			$options = {
				...$options,
				counterpartyBank: [{ id: 'new', text: 'Добавить банк' }, ...response.options.filter(op => op.roles.includes('bank'))],
				counterpartyStockbroker: [{ id: 'new', text: 'Добавить брокера' }, ...response.options.filter(op => op.roles.includes('stockbroker'))],
				counterpartyCryptoex: [{ id: 'new', text: 'Добавить криптобиржу' }, ...response.options.filter(op => op.roles.includes('cryptoex'))],
			};
		}

		if (isBlank($options.currency)) {
			const response = await fetchGet('/api/private/bank_accounts/currency_options');
			$options = { ...$options, currency: response.options };
		}

		mounting = false;
	});
</script>

<Select2 {...formFields.kind} bind:value={bankAccount.kind} />
{#if ['electronic_payment_mean'].includes(bankAccount.kind)}
	<Input {...formFields.electronicPaymentService} bind:value={bankAccount.electronic_payment_service} />
{:else}
	<Select2 {...formFields.bankName} bind:value={bankAccount.counterparty_id} modify={showModalCounterpartyForm} />
{/if}
{#if ['property_realization', 'bankruptcy_proceedings'].includes($procedure.phase) && ['card', 'current', 'special', 'trust_management', 'checking', 'credit', 'deposit', 'budget'].includes(bankAccount.kind)}
	<Checkbox {...formFields.isMain} bind:checked={bankAccount.is_main} />
{/if}
{#if ['card', 'current', 'special', 'trust_management', 'checking', 'credit', 'deposit', 'budget'].includes(bankAccount.kind)}
	<IntegerInput {...formFields.number} bind:value={bankAccount.number} />
{:else if ['brokerage', 'depository_brokerage', 'brokerage_sub', 'individual_investment', 'iban', 'crypto_wallet', 'electronic_payment_mean'].includes(bankAccount.kind)}
	<Input {...formFields.number} bind:value={bankAccount.number} />
{/if}
<InputCurrency {...formFields.balance} bind:value={bankAccount.balance} />
<InputCurrency {...formFields.currentBalance} bind:value={bankAccount.current_balance} />
<Select2 {...formFields.currency} bind:value={bankAccount.currency_id} modify={showModalCurrencyForm} />
<Datepicker {...formFields.openedAt} bind:value={bankAccount.opened_at} />
<Datepicker {...formFields.closedAt} bind:value={bankAccount.closed_at} />
{#if ['property_realization', 'bankruptcy_proceedings'].includes($procedure.phase)}
	<Textarea {...formFields.measuresTaken} bind:value={bankAccount.measures_taken} />
	<Textarea {...formFields.result} bind:value={bankAccount.result} />
{/if}

<ScansSet label='Документы', scansSet={bankAccount.scans_set} disabled={!filledRequiredFields} on:changed={refreshScansSet}/>

<CounterpartyModal
	{counterpartyId}
	{disabled}
	fixedRoles={[counterpartyKind]}
	on:create={({ detail: newCounterpartyId }) => refresh(newCounterpartyId)}
	on:cancel={() => cancelCounterpartyModalForm}
/>

<CurrencyModal {disabled} {currencyKind} on:create={({ detail: newCurrency }) => refreshCurrencies(newCurrency)} on:cancel={() => cancelCurrencyModalForm} />
