<!-- @format -->
<script>
	import { createEventDispatcher, tick, onMount } from 'svelte'
  import { fetchGet } from '~/js/utils/fetch_helpers.js'
	import { isPresent, isArray, truncateString, randomString } from 'utils/tools'
	import Modal, { showModal, hideModal } from '~/svelte/components/modal.svelte'
	import { transmitter } from 'base_stores'
	import PayoutForm from './_form.svelte'
	import BankAccountModal from '~/svelte/bank_accounts/_modal.svelte'
	import CounterpartyModal from '~/svelte/counterparties/_modal.svelte';
	import { cardsTitle } from 'utils/formats'
	import { clearScans } from '~/svelte/_shared/scans_sets/scans_set.js'

	const dispatch = createEventDispatcher();

	export let index = 0;
	export let disabled = false;

	export const newItem = (options = {}) => {
		formOptions = { item: {}, method: 'new', ...options };
		showModal(modalId);
	};

	export const editItem = id => {
		formOptions = { item: { id }, method: 'edit' };
		showModal(modalId);
	};

	export const showItem = id => {
		formOptions = { item: { id }, method: 'show' };
		showModal(modalId);
	};

	const modalId = randomString();
	const accountModalId = randomString();
	const payeeModalId = randomString();

	let isNewInstance
	let formOptions = {}
	let form
	let isFormCompleted = false
	let noBankSelected
	let curBankAccountId

  const payoutSubmit = async () => {
		const formData = form.getItem()
		const result = await form.submitForm()

		if (isPresent(result.item)) {
			if (isArray($transmitter.payouts)) {
				const arr = [...$transmitter.payouts].filter(row => row.id != result.item.payout.id).concat([result.item.payout])
				arr.sort((a,b) => new Date(a.date_at) - new Date(b.date_at))
				$transmitter.payouts = [...arr]
			}

			if (isArray($transmitter.receipts)) {
				if (result.item.receipt.id) {
					const arr = [...$transmitter.receipts].filter(row => row.id != result.item.receipt.id).concat([result.item.receipt])
					arr.sort((a,b) => new Date(a.date_at) - new Date(b.date_at))
					$transmitter.receipts = [...arr]
				} else {
					$transmitter.receipts = $transmitter.receipts.filter(row => row.payout_id != result.item.payout.id)
				}
			}

			const scansSet = await clearScans(formData.scans_set, 'deleted')
			$transmitter.payouts = $transmitter.payouts?.map(s => s.id == result.item.id ? { ...s, scans_set: scansSet } : s)

			dispatch(formOptions.method == 'edit' ? 'update' : 'create', result)
			isNewInstance = undefined
			return result
		}
	}

	const payoutDelete = () => form.deleteItem().then(id => {
		if (isArray($transmitter.payouts)) {
			$transmitter.payouts = $transmitter.payouts.filter(row => row.id != id)
			$transmitter.receipts = $transmitter.receipts.filter(row => row.payout_id != id)
		}

		dispatch('delete', id)

		return id
	})

	const payoutCancel = () => {
		const formData = form.getItem()
		if (isNewInstance) {
			if (formData?.id) { payoutDelete() }
		} else {
			clearScans(formData.scans_set, 'new')
		}
	}

	const payoutDuplicate = () => {
		const { id, payout_lines, ...rest } = form.getItem();
		formOptions = { item: { ...rest }, duplicateMode: true, method: 'new' };
		hideModal(modalId);
		tick().then(() => showModal(modalId));
	};

	const bankAccountNew = ({ detail: currentBankAccountId }) => {
		curBankAccountId = currentBankAccountId;
	//	prepareBankAccountData();
		showModal(accountModalId);
	};

	const bankAccountCreated = ({ detail: id }) => {
		const el = $transmitter.bankAccounts.find(e => e.id == id);

		if (isPresent(el)) {
			const name = `${truncateString(el.kind_name, 18)} ${el.number.slice(-4)} (${el.counterparty_name}${el.is_main ? ' основной' : ''})`;
			form.setData({ bank_accounts: [...form.getData().bank_accounts, { value: id, label: name, currency: el.currency_type }] });
			form.setItem({ [curBankAccountId]: id });
		}
	};

	/*const bankAccountOptions = {
		bankAccountKind: [],
		currency: [],
		counterparty: [],
	};

	const prepareBankAccountData = () => {
		const data = form.getData();
		bankAccountOptions.bankAccountKind = data.bank_account_kinds.map(e => ({ id: e.value, text: e.label }));
		bankAccountOptions.currency = data.bank_account_currencies.map(e => ({ id: e.value, text: e.label }));
	};*/

	const payeeNew = () => {
		showModal(payeeModalId);
	};

	const payeeCreated = async ({ detail: id }) => {
		const response = await fetchGet('/api/private/counterparties', { filters: { roles: { only: 'payee' } } });
		$transmitter.counterparties = response.counterparties;
		const el = $transmitter.counterparties.find(e => e.id == id);

		if (isPresent(el)) {
			form.setData({ payees: [...form.getData().payees, { value: id, label: el.name }] });
			form.setItem({ counterparty_id: id });
		}
	}

	onMount(() => window.addEventListener('beforeunload', () => payoutCancel()))
</script>

<Modal
	{modalId}
	submitButtonDisabled={formOptions.method == 'show' || !isFormCompleted || disabled}
	submitButtonAction={payoutSubmit}
	deleteIconDisabled={formOptions.method == 'new' || disabled}
	deleteButtonAction={payoutDelete}
	deleteConfirmMessage='Вы действительно хотите удалить платеж?'
	cancelButtonAction={payoutCancel}
	showCopyAndSaveButton={ formOptions.method == 'edit' && !disabled }
	copyAndSaveButtonAction={payoutDuplicate}
	form={true}
	showError={false}
	{index}
	size='large'>
	<h2 slot='header'>{cardsTitle('платежа', formOptions.method == 'edit')}</h2>
	<div slot='body'>
		<PayoutForm
			bind:this={form}
			bind:isFormCompleted
			bind:isNewInstance
			{formOptions}
			on:newBankAccount={bankAccountNew}
			on:newPayee={payeeNew}
			{disabled}
		/>
	</div>
</Modal>

<BankAccountModal modalId={accountModalId} index={index + 1} bind:noBankSelected on:create={bankAccountCreated} on:update={bankAccountCreated} />
<CounterpartyModal modalId={payeeModalId} fixedRoles={['payee']} on:create={payeeCreated} {disabled} index={index + 1} />
